@import url('https://fonts.googleapis.com/css2?family=Khand:wght@300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

img {
    max-width: 100%;
}



.Row.Center {
    margin: auto;
    width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.Row.Full {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 132px;
    align-items: center;

}



.Col {
    width: 50%;
}



* {
    box-sizing: border-box;
    color: black;
    transition: all 250ms ease-in-out;
    font-family: 'Outfit', sans-serif;




}

html {
    scroll-behavior: smooth;
}

body {
    background: linear-gradient(180deg, #FFFFFF 0%, #E5E7E9 100%);
}

figure {
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

ul {
    list-style: none;
    margin-block-end: 0;
    margin-block-start: 0;
    padding-inline-start: 0;
}

a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}



.a-icon svg {
    margin-left: 6px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {

    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin: 0;
}

.TituloBordeIzquierdo {
    border-left: 4px solid #3A9444;
    padding-left: 12px;
    font-family: 'Khand';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 116%;
    width: fit-content;
    margin: auto;
    text-transform: uppercase;
}

/*Btn Anima*/
.btnFeliz {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    width: fit-content;
    border-radius: 999px;
    clip-path: inset(0);
}

.btnFeliz::before {
    position: absolute;
    content: " ";
    width: 24px;
    height: 24px;
    background: #3A9444;
    border-radius: 999px;
    animation: felix linear infinite 4s;

}

.btnFeliz span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 32px;
    color: #653313;
    height: 48px !important;
    backdrop-filter: blur(12px);
    background: rgba(255, 255, 255, 0.1);
    border-radius: 999px;

}



@keyframes felix {

    0% {
        left: -10%;
        top: auto;
    }


    100% {
        left: 110%;
        top: auto;
    }
}





/*HEADER*/
header {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 132px;
    background: rgb(255, 255, 255);
    backdrop-filter: blur(24px);
    -webkit-backdrop-filter: blur(24px);
    z-index: 99;
}

header#movil {
    display: none;
}

header .section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

header .section-header#Main {
    height: 75px;
    justify-content: space-between;
}


/*NAVEGACION PRINCIPAL*/
#Navegacion-principal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}

#Navegacion-principal ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 64px;
}

#Navegacion-principal ul li a {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}



/*Grupo de Contacto y Hamburguesa*/
.group-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 32px;

}




/*HAMBURGUER*/

/*normal*/
#Hamburguer {
    width: 32px;
    height: 24px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
}

#Hamburguer span {
    position: absolute;
    width: 100%;
    height: 2px;
    background: black;
    left: 0;
}

#Hamburguer span#uno {
    top: 0;
}

#Hamburguer span#dos {
    top: 8;

}

#Hamburguer span#tres {
    bottom: 0;
}

/*hover*/



#Hamburguer.active span#uno {
    transform: rotate(220deg);
    top: 9px;
}

#Hamburguer.active span#dos {
    position: static;
    width: 0;
}

#Hamburguer.active span#tres {
    transform: rotate(135deg);
    top: 9px;
}


/*MEGAMENU MATERIALES*/
.section-header#MaterialesH {
    height: 100px;
    justify-content: center;
}

.section-header#MaterialesH nav ul {
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
    justify-content: center;
    row-gap: 24px;

}

.section-header#MaterialesH nav ul li a {
    font-size: 14px;
    text-transform: uppercase;
}

.section-header#MaterialesH nav ul li a:hover {
    text-decoration: underline;
}

/*MEGAMENU MENU*/

.section-header#Menu {
    justify-content: center;
    gap: 32px;
    height: 100px;
}

.section-header#Menu a {
    font-size: 14px;

}

.section-header#Menu a:hover {
    text-decoration: underline;
}




/*REDES SOCIALES COMPONENTE*/
.RedesSociales {
    display: flex;
    flex-direction: row;
    gap: 18px;

}

.RedesSociales li {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    min-width: fit-content;
}

.RedesSociales li a {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background: #ECECEC;
    width: 48px;
    height: 48px;
    border-radius: 999px;


}

.RedesSociales li:hover a img {
    filter: invert(100%);
}

.RedesSociales li span {
    display: none;
}

.RedesSociales li a:hover {
    background: black;

}

.RedesSociales li a:hover svg {
    stroke: white;
}









/*BOTONES*/

button {
    border: none;
    background: transparent;
    cursor: pointer;
    border: solid 2px transparent;
}


button.Cotizacion-btn {
    height: 48px;
    border-radius: 999px;
    background: #3A9444;
    padding: 0 32px;
    color: white;
}




button.Primary,
a.Primary {


    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


    padding: 0 32px;
    height: 48px;

    background: #3A9444;
    border-radius: 8px;

    font-family: 'Outfit', sans-serif;


    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 116.5%;


    color: #FFFFFF;
}

button.Secondary,
a.Secondary {
    text-decoration: none;

    padding: 0 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 48px;

    background: #ECECEC;
    border: 1px solid #D3D3D3;
    border-radius: 8px;

    font-family: 'Outfit', sans-serif;


    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 116.5%;



    color: #706F6F;


}





button.Terciary,
a.Terciary {
    height: 48px;
    padding: 0 32px;
    border-radius: 999px;
    border: 2px solid #653313;

    width: fit-content;
    background: #653313;
    color: white;
}

button.Terciary:hover,
a.Terciary:hover {
    transform: scale(1.1);
}

/*BOTONES CON ICONOS */
.CloseButton {
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 12px;
    top: 12px;
    border-radius: 999px;


}

.CloseButton:hover {
    background: #ECECEC;
}

.CloseButton:active {
    background: black;
    color: #ECECEC;
}

.CloseButton:active svg {
    stroke: white;
}

/*POPUP*/


.Overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgb(0, 0, 0, 0.5);

    visibility: hidden;
    opacity: 0;
    padding-top: 100px;
    transition: 100ms all ease-in-out;
    z-index: 100;

}

.Overlay.true {
    padding-top: 0;
    visibility: visible;
    opacity: 1;
    backdrop-filter: blur(4px);

}

.Popup {
    display: flex;
    flex-direction: column;
    position: relative;

    width: 475px;



    padding: 64px 24px 24px;
    gap: 48px;



    background: #FFFFFF;
    border-radius: 12px;

}


.Popup h1 {
    font-family: 'Outfit', sans-serif;


    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 116%;
    color: #000000;
    text-align: center;
    margin: 0;
    margin-bottom: 32px;
}

.Popup p {
    text-align: center;
    margin: 0;
    margin-bottom: 32px;
}


/*FORMS*/
form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 18px;

    max-width: 100%;
    background: #ECECEC;
    border-radius: 16px;
    z-index: 1;
}


/*INPUTS*/


.InputContent {
    display: flex;
    position: relative;
    width: 100%;
    clip: auto;
    border-radius: 8px;
}

input,
textarea {
    width: 100%;
    display: flex;
    background: #FFFFFF;
    border-radius: 8px;
    height: 48px;
    padding-inline-start: 12px;
    font-family: 'Outfit', sans-serif;


    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 116.5%;
    letter-spacing: 0.01em;
    color: #706F6F;
    border: solid 2px transparent;


}

textarea {
    height: 96px;
    min-width: 100%;
    outline: none;
}

input:focus {
    outline: none;
    border-color: #653313;
}

input:focus~label,
input:valid~label {
    margin-top: 4px;
    margin-left: 8px;
    color: #653313;
    font-size: 12px;
}

textarea~label {
    margin-top: 4px;
    margin-left: 8px;
    color: #706F6F;
    font-size: 12px;
}





label {
    margin-top: 18px;
    margin-left: 18px;
    position: absolute;
    font-family: 'Outfit', sans-serif;


    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 116.5%;
    letter-spacing: 0.01em;

    color: #706F6F;
    border-radius: 2px;
    transition: 100ms all ease-in-out;


}

/*demas*/
a.aviso {

    font-family: 'Outfit', sans-serif;


    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;

    letter-spacing: 0.01em;

    color: #000000;
    text-decoration: none;

    /* Inside auto layout */

}

a.aviso span {
    text-decoration: underline;
    margin-left: 3px;
}

/*COTIZACION POPUP*/

.Popup#Cotizar .bloqueForm form .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    width: 100%;
}


.Popup#Cotizar .bloqueForm {

    display: none;
}

.Popup#Cotizar .bloqueForm#Paso3 {

    flex-direction: column;

    justify-content: center;
}

.Popup#Cotizar .bloqueForm#Paso3 svg {
    text-align: center;
    width: 100%;
    margin-bottom: 24px;
    stroke: rgba(58, 148, 68, 1);

}





/*ALERTS*/


.alertInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 12px;
    height: 40px;
    justify-content: left;
    width: 100%;
    text-transform: uppercase;

    background: rgba(188, 0, 0, 0.2);
    border-radius: 8px;
    height: 40px;
    color: rgba(187, 76, 76, 1);
    font-family: 'Khand';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 116.5%;
}

main {
    display: flex;
    flex-direction: column;
    padding-top: 96px;
    gap: 144px;
}


/*CONTACTO*/

#Contacto #Portada {


    justify-content: center;
    align-items: center;
    height: 90vh;
}

#Contacto .Row .Col form {
    width: 400px;
    padding: 0;
    background: transparent;
    align-items: flex-start;
    align-content: center;
}

#Contacto .Row .Col form h1 {
    font-family: 'Outfit', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 116.5%;
}


#Contacto .Row .Col form input,
textarea {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    background: #ECECEC;
    border-color: #D3D3D3;
    border-radius: 8px;
    border-width: 1px;
}

#Contacto .Row .Col form input:focus,
textarea:focus {
    border-color: #653313;
    border-width: 2px;
}




#Contacto .Row .Col img {
    border-radius: 12px;
}

#Contacto .Row .Col #redes {
    display: flex;
    margin-top: -24px;
    margin-left: -48px;
    gap: 24px;

}

#Contacto .Row .Col #redes .RedesSociales li a {
    background: #653313;
}

#Contacto .Row .Col #redes .RedesSociales li a svg {
    stroke: white;
}

#Contacto .Row .Col #redes .RedesSociales li a img {
    filter: invert(100%);
    border-radius: 0;
}

#Contacto .Row .Col #redes h4 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 18px;
    gap: 10px;
    height: 48px;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    border-radius: 100px;
    margin: 0;
    filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.5));


    font-family: 'Khand';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 116.5%;
}

#Contacto .Row .Col #Paso2 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
    gap: 12px;
}

#Contacto .Row .Col #Paso2 svg {
    stroke: #3A9444;
}

/*BARRA INFERIOR CTAS*/
#BarraInferior {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    position: fixed;
    bottom: 32px;
    left: 0px;
    backdrop-filter: blur(24px);
    -webkit-backdrop-filter: blur(24px);
    z-index: 97;
    border-radius: 12px;
    left: 50%;
    padding: 6px;
    transform: translate(-50%, 0);
    width: fit-content;
    background: #ffffff;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.125);

}

/*MATERIAL SINGLE*/
#MaterialSingle {
    width: 100%;
    background: #E5E7E9;

}

#MaterialSingle #producto {
    height: 100vh;
    flex-direction: row;
    justify-content: space-between;


}

#MaterialSingle .Row .Col#contenido {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: fit-content;
}

#MaterialSingle .Row .Col#contenido .nombre {
    font-family: 'Khand';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 116%;
    text-transform: uppercase;
}

#MaterialSingle .Row .Col#contenido p {
    width: 90%;
}




#MaterialSingle .Row .Col#imagen img {
    border-radius: 999px 999px 36px 36px;

    width: 100%;

}

#MaterialSingle .Row .Col#imagen .NavegacionSwiper {
    z-index: 999;
    margin-top: -24px;
}

#MaterialSingle .Row .Col#imagen .NavegacionSwiper button {
    background: white;
    z-index: 1;
}

/*Cultivos*/
#MaterialSingle #Cultivos {
    display: flex;
    flex-direction: column;
    gap: 12px;

}

#MaterialSingle #Cultivos h3 {
    font-family: 'Khand';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 116.5%;
    text-decoration-line: underline;
    text-transform: uppercase;
}


#MaterialSingle #Cultivos ul {
    display: flex;
    flex-direction: row;
    background: white;
    width: fit-content;
    padding: 6px;
    border-radius: 999px;
    gap: 6px;


}

#MaterialSingle #Cultivos ul li {
    display: flex;
    justify-content: left;
    align-items: center;


}

#MaterialSingle #Cultivos ul li .image {
    border-radius: 99px;
    min-width: 48px;
    height: 48px;
}

#MaterialSingle #Cultivos ul li span {
    font-size: 0px;
}

#MaterialSingle #Cultivos ul li:hover span {

    margin: 0 12px;

    font-size: 16px;


}



/*Definicion de cultivos*/
.imagen#Fresa .image {
    background-image: url("./images/fresa.jpg");
}

.imagen#Arandano .image {
    background-image: url("./images/arandano.jpg");
}

.imagen#Zarzamora .image {
    background-image: url("./images/zarzamora.jpg");
}

.imagen#Frambuesa .image {
    background-image: url("./images/frambuesa.jpg");
}

.imagen#Jitomate .image {
    background-image: url("./images/jitomate.jpg");
}

.imagen#Pepino .image {
    background-image: url("./images/pepino.jpg");
}

.imagen#Pimiento .image {
    background-image: url("./images/pimiento.jpg");
}





.otrosProductos {
    width: 1200px;
    margin: auto;
    margin-top: 144px;
    margin-bottom: 48px;
    border-left: 4px solid #3A9444;
    padding-left: 12px;
    font-family: 'Khand';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 116%;
    text-transform: uppercase;
}

.SwiperMateriales {
    margin-left: 132px !important;
}

.SwiperMateriales .swiper-slide {
    width: fit-content;

}



.materialesSlide {
    position: relative;
    width: 260px;
    height: 260px;
    padding: 12px;
    border-radius: 999px;

    margin: auto;
}

.materialesSlide::after {
    position: absolute;
    content: " ";
    width: 250px;
    height: 250px;
    border-radius: 999px;
    background: white;
    z-index: -10;
}

.materialesSlide::before {
    position: absolute;
    content: " ";
    width: 260px;
    height: 260px;
    border-radius: 999px;
    background: linear-gradient(180.1deg, rgba(58, 148, 68, 0) 50%, #3A9444 100%);
    z-index: -10;
}

.materialesSlide:hover {
    padding: 0;
    border: 5px solid #3A9444;
}



.materialesSlide span {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 999px;
    color: white;
    text-transform: uppercase;
    background-blend-mode: multiply, normal;
    text-align: center;
    padding: 18px;
}

.materialesSlide span .verDetalle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    position: absolute;
    width: 36px;
    height: 36px;
    bottom: -50px;
    background: #3A9444;
    border-radius: 999px 999px 0px 0px;
}

.materialesSlide:hover span .verDetalle {
    bottom: 1px;
}

.materialesSlide span .verDetalle svg {
    stroke: white;
}

/*NAVEGACION SWIPER*/
.NavegacionSwiper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 24px;
    margin-top: 48px;

}

.NavegacionSwiper .Izquierda,
.NavegacionSwiper .Derecha {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E5E7E9;
    border-radius: 100px;
    width: 48px;
    height: 48px;
    position: relative;
    clip-path: circle(52.5%);


}

.NavegacionSwiper .Izquierda svg,
.NavegacionSwiper .Derecha svg {
    position: absolute;

}

/*Izquierda*/
.NavegacionSwiper .Izquierda svg:nth-child(2) {
    margin-left: 60px;
}

.NavegacionSwiper .Izquierda:hover svg:nth-child(2) {
    margin-left: 8px;
}

.NavegacionSwiper .Izquierda:hover svg:nth-child(1) {
    margin-left: -8px;
}

.NavegacionSwiper .Izquierda:active {
    transition: all 100ms;
}

.NavegacionSwiper .Izquierda:active svg:nth-child(1) {
    margin-left: -80px;
}

.NavegacionSwiper .Izquierda:active svg:nth-child(2) {
    margin-left: 0px;
}

/*Derecha*/
.NavegacionSwiper .Derecha svg:nth-child(2) {
    margin-right: 60px;
}

.NavegacionSwiper .Derecha:hover svg:nth-child(2) {
    margin-right: 8px;
}

.NavegacionSwiper .Derecha:hover svg:nth-child(1) {
    margin-right: -8px;
}

.NavegacionSwiper .Derecha:active svg:nth-child(1) {
    margin-right: -80px;
}

.NavegacionSwiper .Derecha:active svg:nth-child(2) {
    margin-right: 0px;
}


/*INICIO - PORTADA*/
#Inicio #Portada {
    height: 90vh;
    align-items: center;
    position: relative;

}

#Inicio #Portada #contenido {
    display: flex;
    flex-direction: column;
    gap: 24px;

}

#Inicio #Portada #contenido h1 {
    font-family: 'Outfit', sans-serif;
    width: 90%;
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 90%
}

#Inicio #Portada #contenido p {
    width: 80%;

    font-family: 'Outfit', sans-serif;


    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 116.5%;

}

#Inicio #Portada #Collage {
    position: relative;
    width: 525px;
    height: 525px;
}

#Inicio #Portada #Collage figure {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100px;
    position: absolute;

}

#Inicio #Portada #Collage #imagen1 {

    border-radius: 999px;

    animation: Collage1 18s ease-in-out infinite;
}

#Inicio #Portada #Collage #imagen2 {

    border-radius: 999px;
    animation: Collage2 18s ease-in-out infinite;

}

#Inicio #Portada #Collage #imagen3 {

    animation: Collage3 18s ease-in-out infinite;
}





#Inicio #MaterialesFigure {
    flex-direction: column;
}

#Inicio #MaterialesFigure #FigureImg {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
}

#Inicio #MaterialesFigure #FigureImg::after {
    content: " ";
    height: 440px;
    width: 440px;
    border: solid 4px #3A9444;
    border-radius: 999px;
    position: absolute;
    filter: blur(4px);
    z-index: -10;
    animation: rutine 2s ease-in-out infinite;
    animation-delay: .25s;

}

#Inicio #MaterialesFigure #FigureImg::before {
    content: " ";
    height: 500px;
    width: 500px;
    border: solid 2px #3A9444;
    border-radius: 999px;
    position: absolute;
    filter: blur(6px);
    z-index: -10;
    animation: rutine 2s ease-in-out infinite;
}


#Inicio #MaterialesFigure div #FigureImg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    width: 400px;
    border-radius: 9999px;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.3);

}

#Inicio #MaterialesFigure div #FigureImg .mate {
    position: absolute;
}

@keyframes rutine {
    0% {
        transform: scale(.75);
        opacity: 1;
    }

    100% {
        transform: scale(1.25);
        opacity: 0;
    }

}





@keyframes Collage1 {
    0% {
        height: 525px;
        left: 0;
        top: 0;
        width: 250px
    }

    16.6% {
        border-radius: 48px;
        height: 250px;
        left: 0;
        top: 0;
        width: 250px
    }

    33.2% {
        border-radius: 999px;
        height: 250px;
        left: 275px;
        top: 0;
        width: 250px
    }

    49.8% {
        border-radius: 999px;
        height: 525px;
        left: 275px;
        top: 0;
        width: 250px
    }

    66.4% {
        border-radius: 48px;
        height: 250px;
        left: 275px;
        top: 275px;
        width: 250px
    }

    83% {
        border-radius: 999px;
        height: 250px;
        left: 0;
        top: 275px;
        width: 250px
    }

    to {
        height: 525px;
        left: 0;
        top: 0;
        width: 250px
    }
}



@keyframes Collage2 {
    0% {
        border-radius: 999px;
        height: 250px;
        left: 275px;
        top: 0;
        width: 250px
    }

    16.6% {
        border-radius: 999px;
        height: 525px;
        left: 275px;
        top: 0;
        width: 250px
    }

    33.2% {
        border-radius: 48px;
        height: 250px;
        left: 275px;
        top: 275px;
        width: 250px
    }

    49.8% {
        border-radius: 999px;
        height: 250px;
        left: 0;
        top: 275px;
        width: 250px
    }

    66.4% {
        border-radius: 999px;
        height: 525px;
        left: 0;
        top: 0;
        width: 250px
    }

    83% {
        border-radius: 48px;
        height: 250px;
        left: 0;
        top: 0;
        width: 250px
    }

    to {
        border-radius: 999px;
        height: 250px;
        left: 275px;
        top: 0;
        width: 250px
    }
}


@keyframes Collage3 {
    0% {
        border-radius: 48px;
        height: 250px;
        left: 275px;
        top: 275px;
        width: 250px
    }

    16.6% {
        border-radius: 999px;
        height: 250px;
        left: 0;
        top: 275px;
        width: 250px
    }

    33.2% {
        border-radius: 999px;
        height: 525px;
        left: 0;
        top: 0;
        width: 250px
    }

    49.8% {
        border-radius: 48px;
        height: 250px;
        left: 0;
        top: 0;
        width: 250px
    }

    66.4% {
        border-radius: 999px;
        height: 250px;
        left: 275px;
        top: 0;
        width: 250px
    }

    83% {
        border-radius: 999px;
        height: 525px;
        left: 275px;
        top: 0;
        width: 250px
    }

    to {
        border-radius: 48px;
        height: 250px;
        left: 275px;
        top: 275px;
        width: 250px
    }
}

/*SLIDE PORTADA MANUAL*/



.slide-inicio-portada {
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 50px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    clip-path: inset(0);
}

.slide-inicio-portada::after {
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #FFFFFF 45%, rgba(255, 255, 255, 0) 60%);
}



.slide-inicio-portada .contenido {
    display: flex;
    gap: 48px;
    animation: sl linear infinite 200s;
}









@keyframes sl {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: -1000%;
    }
}



/*INICIO - SOMOS*/
#Inicio #Somos {
    font-family: 'Khand';
    align-items: center;
    justify-content: center;
    background-blend-mode: multiply, normal;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    gap: 24px;
    margin-bottom: 144px;
    padding: 132px;
}

#Inicio #Somos .Isotipo {
    width: 92.52px;
}

#Inicio #Somos h1 {
    font-family: 'Khand';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 116%;
    color: #FFFFFF;
}

#Inicio #Somos p {
    font-family: 'Outfit', sans-serif;


    font-style: normal;
    font-size: 18px;
    line-height: 116.5%;
    text-align: center;
    color: #FFFFFF;
    width: 768px;
}

#Inicio #Somos #BloqueCards {
    display: flex;
    flex-direction: row;
    gap: 0px;
    justify-content: space-between;
    margin-top: 96px;
}


#Inicio #Somos #BloqueCards .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 48px;
    gap: 24px;
    width: 250px;
    height: 232px;
    background: #FFFFFF;
    border-bottom: 5px solid #653313;
    margin-bottom: -50%;
    text-align: center;
}




#Inicio #Somos #BloqueCards .card:hover {
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.5);
    transform: scale(1.1);
    border-radius: 4px;
}


#Inicio #Somos #BloqueCards .Card h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 116%;
    text-align: center;
    color: #000000;
}




/*INICIO - MATERIALES*/
#Inicio #Materiales {
    gap: 96px;
}

#Inicio #Materiales #texto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 12px;
}


/*INICIO - CONOCEMOS*/

#Inicio #Conocemos {
    justify-content: center;
    align-items: center;
    background-blend-mode: multiply, normal;
    background-position: 0;
    background-repeat: repeat-x space;
    background-size: contain;
    background-image: url("./images/arco.svg");
    padding: 32px;
    transition: linear;

}

#Inicio #Conocemos h2 {
    font-family: 'Khand';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 116%;
    color: #3A9444;
    text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
}

/*FOOTER*/
footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    margin-top: 144px;
}

footer #contenidoPrincipal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 64px 132px;
    justify-content: flex-start;
    gap: 24px;
}

footer #contenidoPrincipal #Logo img {
    height: 100px;
}



footer #contenidoPrincipal .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 32px;
    align-items: center;
    border-bottom: 2px solid #D3D3D3;
    padding: 24px 0;
}

footer #contenidoPrincipal .row h3 {
    width: fit-content;
    margin: 0;


}

footer #contenidoPrincipal .row #Logo {
    height: 200px;
}

footer #contenidoPrincipal .row nav {
    width: max-content;
}

footer #contenidoPrincipal .row nav ul {
    display: flex;
    flex-direction: row;
    gap: 16px;
    row-gap: 12px;
    flex-wrap: wrap;
    width: 100%;
    justify-content: right;
}

footer #contenidoPrincipal .row nav ul li {
    display: flex;
}

footer #contenidoPrincipal .row nav ul li:last-child::after {
    content: "";
}

footer #contenidoPrincipal .row nav ul li:hover {
    text-decoration: underline;
}

footer #contenidoPrincipal .row nav ul li::after {
    content: "•";
    padding-left: 6px;
}

footer #contenidoPrincipal .row nav ul li a {
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 14px;

}

footer #contenidoPrincipal .row .RedesSociales li span {
    display: block;
}





footer #barraInferior {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 6px 132px;
    background: #653313;
    height: 48px;
}

footer #barraInferior * {
    color: white;
    font-family: 'Outfit', sans-serif;


    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    align-content: center;
}




/*AVISO DE PRIVACIDAD*/
#Aviso-de-privacidad {
    max-width: 768px;
    margin: auto;
    margin: 96px auto;
    gap: 32px;
}

#Aviso-de-privacidad h1 {
    text-align: center;
    font-family: 'Khand';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 116%;
}

#Aviso-de-privacidad p {
    text-align: justify;
}


/*SOBRE NOSOTROS - PORTADA*/
#SobreNosotros #Portada {
    align-items: center;
    height: 90vh;
}

#SobreNosotros #Portada .Col#texto {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

#SobreNosotros #Portada .Col img {
    border-radius: 999px 999px 36px 36px;
}

#SobreNosotros #Portada .Col h1 {
    font-family: 'Outfit', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 116%;
}

#SobreNosotros #Portada .Col p {
    font-family: 'Outfit', sans-serif;


    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 116.5%;
}


/*SOBRE NOSOTROS - OPINIONES*/
#Opiniones {
    display: flex;
    flex-direction: row;
    padding: 0;
}

#Opiniones .texto {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 132px;
}


#Opiniones .SwiperOpiniones {
    width: 70%;
}




#Opiniones .SwiperOpiniones .OpinionCard {
    display: flex;
    flex-direction: column;
    gap: 12px;
    background: #3A9444;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(24px);
    border-radius: 32px;
    padding: 24px;
    width: 480px;
}

#Opiniones .SwiperOpiniones .OpinionCard div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    position: relative;

}

#Opiniones .SwiperOpiniones .OpinionCard div .user {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 999px;
    background: #E5E7E9;
}

#Opiniones .SwiperOpiniones .OpinionCard div h4 {
    font-family: 'Khand';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    color: white;
    gap: 6px;
}

#Opiniones .SwiperOpiniones .OpinionCard div h4 span {
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: white;
}

#Opiniones .SwiperOpiniones .OpinionCard div .quote {
    position: absolute;
    right: 0;
}


#Opiniones .SwiperOpiniones .OpinionCard p {
    color: white;
}


/*SOBRE NOSOTROS COLLAGE INFERIOR*/
#SobreNosotros #CollageSN {
    gap: 96px;
    margin: 144px 0;
}

#SobreNosotros #CollageSN p {
    width: 75%;
    text-align: center;
    font-size: 18px;
}

#SobreNosotros #CollageSN #graficos {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    width: 100%;

}

#SobreNosotros #CollageSN #graficos figure {
    display: block;
    width: 15vw;
    height: 15vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#SobreNosotros #CollageSN #graficos figure#C1 {
    border-radius: 48px;
}

#SobreNosotros #CollageSN #graficos figure#C2 {
    border-radius: 999px;
    width: 30vw;

}

#SobreNosotros #CollageSN #graficos figure#C3 {
    border-radius: 999px 999px 138px 138px;
}

#SobreNosotros #CollageSN #graficos figure#C4 {
    border-radius: 999px;
}



/*Boton de WhatsApp Fijo*/
#WaFixed {
    position: fixed;
    bottom: 32px;
    right: 32px;
    background: white;
    padding: 12px;
    border-radius: 999px;
    z-index: 96;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.15);
    border: solid 2px transparent;
}

#WaFixed span {
    font-size: 0px;
}

#WaFixed:hover {
    border-color: #3A9444;
}

#WaFixed:hover span {
    font-size: 16px;
    margin: 0 12px;
}



/*MEDIA QUERY*/
@media (max-width: 600px) {
    .SwiperMateriales {
        margin-left: 0 !important;
    }


    main {
        padding-top: 144px;
        gap: 32px;
    }

    .Row.Center {
        flex-direction: column;
        width: 100%;
        padding: 32px;
        gap: 48px;
    }

    .Row.Full {
        padding: 32px;
        flex-direction: column;
        width: 100%;
        padding: 32px;
        gap: 48px;
    }

    .Col {
        width: 100%;
    }

    header#escritorio {
        display: none;
    }

    header#movil {
        position: fixed;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        height: fit-content;

    }

    header#movil #Main {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }


    #MenuFull {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;





    }

    #MenuFull #Navegacion-principal {
        position: static;
        left: 0;
        transform: translate(0);
        justify-content: flex-start;
    }

    #MenuFull #Navegacion-principal ul {
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
    }


    #MenuFull #liMaterialesMovil {
        background: #f2f2f254;
        border: 1px solid #e0e0e088;
        padding-top: 20px;
        padding-left: 20px;

        width: calc(100% + 50px);
        margin-left: -20px;
    }

    #MenuFull #liMaterialesMovil a {
        flex-direction: column;
        text-transform: uppercase;
    }

    #MenuFull #liMaterialesMovil a.a-icon {
        flex-direction: row;
    }

    #MenuFull #liMaterialesMovil span {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
    }

    #MenuFull #liMaterialesMovil nav {
        white-space: nowrap;
        overflow-x: auto;
        flex-direction: row;
        width: calc(100vw - 40px);
        padding-bottom: 6px;


    }

    #MenuFull #liMaterialesMovil nav ul {
        flex-direction: row;
        width: fit-content;
        justify-content: center;
        align-items: center;
        align-content: center;

        height: 40px;
    }

    #MenuFull #liMaterialesMovil nav ul li a {
        padding-right: 10px;
        font-size: 14px;
    }



    #MenuFull .group-btns {
        flex-direction: column-reverse;
        position: absolute;
        bottom: 20px;
        width: calc(100% - 40px);
    }

    #MenuFull .Cotizacion-btn {
        width: 100%;
        bottom: 20px;
        left: 20px;

    }

    #MenuFull .RedesSociales {
        width: 100%;
        justify-content: space-between;
    }

    .Cotizacion-btn {
        height: 64px !important;
    }





    /*COTIZACION*/
    .Overlay {
        align-items: end;
        backdrop-filter: blur(0px);
    }

    .Popup {
        border-radius: 12px 12px 0 0;
        height: 80vh;
        width: 100%;

        justify-content: center;
        padding: 20px;
        border-radius: 0;
    }

    footer {
        margin-top: 144px;
    }

    /**FOOTER**/
    footer #contenidoPrincipal {
        padding: 32px;
    }



    footer #contenidoPrincipal .row {
        flex-direction: column;
        align-items: flex-start
    }

    footer #contenidoPrincipal .row .RedesSociales {

        width: 100%;
        margin-top: 12px;
    }



    footer #contenidoPrincipal .row .listaMateriales ul {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 24px;
    }

    footer #contenidoPrincipal .row .listaMateriales ul li a {
        width: 100%;

    }

    footer #barraInferior {
        padding: 32px;
    }

    footer #barraInferior a {
        font-size: 12px;
    }

    /*INICIO - PORTADA*/
    #Inicio #Portada {
        width: 100%;
        flex-direction: column-reverse;
        height: fit-content;


    }

    #Inicio #Portada #contenido h1 {
        font-size: 48px;
        line-height: 116.5%;
        width: 100%;
    }

    #Inicio #Portada #contenido p {
        width: 100%;
    }


    #Inicio #Portada .Col #Collage {
        position: absolute;
        transform: scale(.6);
        width: auto;
        height: auto;
        top: 124px;
    }

    /*INICIO - SOMOS*/
    #Inicio #Somos {
        padding: 32px;
    }

    #Inicio #Somos h1 {
        text-align: center;
    }

    #Inicio #Somos p {
        width: 100%;
    }


    #Inicio #Somos #BloqueCards {
        flex-direction: column;
        padding-top: 0;
        margin-top: 24px;
        width: 100%;
        gap: 24px;
    }



    #Inicio #Somos #BloqueCards .card {
        margin: 0;
        padding: 36px 24px;
        gap: 12px;
        width: 100%;
        height: fit-content;
    }

    /*INICIO - CONOCEMOS*/

    #Inicio #Conocemos h2 {
        text-align: center;
    }


    /*INICIO - MATERIALES*/

    #Inicio #Materiales {
        gap: 0;
        padding: 0;
    }

    #Inicio #Materiales #texto {
        padding: 32px;
    }





    /*SOBRE NOSOTROS - PORTADA*/
    #SobreNosotros #Portada {
        align-items: center;
    }

    #SobreNosotros #Portada .Col#texto {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    #SobreNosotros #Portada .Col img {}

    #SobreNosotros #Portada .Col h1 {}

    #SobreNosotros #Portada .Col p {}

    #SobreNosotros #CollageSN {
        margin: 48px 0;
    }

    #Opiniones {
        padding: 32px;
        flex-direction: column;

    }

    #Opiniones .SwiperOpiniones {
        width: 100%;
    }

    #Opiniones .SwiperOpiniones .OpinionCard {
        width: 100%;
    }

    #Opiniones .texto {
        width: 100%;
        margin: 0;
        display: flex;
        align-items: center;
        text-align: center;
    }

    #SobreNosotros #CollageSN p {
        width: 100%;
    }

    #SobreNosotros #CollageSN {}

    #SobreNosotros #CollageSN #graficos {
        white-space: nowrap;
        overflow-x: auto;
        transform: scale(2);
        width: 100%;
    }





    /*AVISO DE PRIVACIDAD*/
    #Aviso-de-privacidad {
        padding: 32px;

    }

    #BarraInferior {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        transform: translate(0);
        border-radius: 0;
        flex-direction: column;
        padding: 0;
    }

    #BarraInferior a {
        display: none;
    }

    #BarraInferior span {
        display: none;
    }

    #BarraInferior a,
    #BarraInferior button {
        width: 100%;
        height: 72px;
        border-radius: 0;
    }

    /*CONTACTO*/
    #Contacto #Portada {
        flex-direction: column-reverse;
        height: fit-content;
    }

    #Contacto #Portada .Col img {
        width: 100%;
    }

    #Contacto .Row .Col form {
        width: 100%;
    }

    #Contacto #Portada .Col #redes {
        flex-direction: column;
        margin: auto;
        margin-top: -24px;
    }

    #Contacto #Portada .Col #redes h4 {
        width: fit-content;
        margin: auto;
    }

    #Contacto #Portada .Col #redes .RedesSociales {
        width: 100%;
        justify-content: space-between;
        gap: unset;
    }


    /*MATERIAL SINGLE*/
    #MaterialSingle {}

    #MaterialSingle #producto {
        height: fit-content;
        flex-direction: column-reverse;
        justify-content: space-between;


    }

    #Inicio #Conocemos {
        background-size: 50%;
        background-repeat: repeat-x;
    }

    #Collage {
        display: none;
    }

    #WaFixed {
        bottom: 12px;
        right: 12px;
    }

}